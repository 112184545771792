<template>
  <div>
    <post-title-input
      ref="titleBox"
      :inputTitle="postData.title"
      :inputDescription="postData.description"
      :inputTags="postData.tags.map((x) => x.name).join(' ')"
      @titleChange="(ev) => (title = ev)"
      @descriptionChange="(ev) => (description = ev)"
      @tagsChange="(ev) => (tags = ev)"></post-title-input>
    <div class="sections-container">
      <post-submit-section
        v-for="(section, i) in postData.album.sections"
        :editImageMode="true"
        :previousImage="section.image"
        :previousText="section.content"
        :previousExternalLink="section.external_url"
        :key="section.id"
        :ref="'section' + i"
        @remove="() => updateImage(i, 'remove')"
        @newimage="(e) => updateImage(i, e)"
        @newwords="(e) => updateContent(i, e)"
        @newlink="(e) => updateLink(i, e)"></post-submit-section>
    </div>
    <div class="extra-section">
      <div
        class="main-image"
        v-if="
          this.postData.album.sections.filter((x, i) => x.image !== '').length
        ">
        <p class="text main-image-text" v-if="imageSections.length">
          Which image to use as the thumbnail?
        </p>
        <div class="image-container">
          <div
            v-for="(section, i) of imageSections"
            :key="i"
            class="small-image-container">
            <img
              :src="imageSources[i]"
              alt="loading"
              class="small-image"
              :class="[mainImage === i ? 'clicked' : '']"
              @click="mainImage = i"
              :style="!imageSources[i] ? 'display: none;' : ''" />
          </div>
        </div>
        <!-- <div class="make-private-box">
          <label for="make-private-input" class="text"
            >make this a private post?</label
          >
          <input id="make-private-input" type="checkbox" v-model="isPrivate" />
        </div> -->
      </div>
    </div>
    <div class="buttons-container-row">
      <button
        class="button button-primary-filled"
        :class="buttonDisabled ? 'disabled' : ''"
        @click="submitForm()">
        finish edit
      </button>
      <!-- <button class="button button-primary">preview post</button> -->
      <button class="button-dark button" @click="$router.back()">cancel</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostSubmitSection from "./PostSubmitSection.vue";
import PostTitleInput from "./PostTitleInput.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  components: { PostSubmitSection, PostTitleInput },
  name: "PostEditForm",
  props: {
    postData: Object,
  },
  mixins: [imageMixin],
  data() {
    return {
      title: "",
      description: "",
      tags: "",
      sections: [],
      mainImage: this.postData.main_image,
      buttonDisabled: false,
      imageSources: [],
    };
  },
  methods: {
    updateImage(i, image) {
      if (!this.sections[i]) {
        //if e.g. only section 2 is edited, no need to send and upload sections 0 and 1
        this.sections[i] = { image: undefined, text: "" };
      }
      console.log(image);
      this.sections[i].image = image;
      if (image !== "remove") {
        this.imageSources[i] = this.loadingImageSrc;
        if (image == "") {
          image =
            this.postData.album.sections[i].image ||
            this.postData.album.sections[i].external_url;
        }
        this.getSrc(image).then((src) => {
          this.imageSources[i] = src;
        });
      } else {
        this.imageSources[i] = undefined;
      }
    },
    updateLink(i, link) {
      if (!this.sections[i]) {
        //if e.g. only section 2 is edited, no need to send and upload sections 0 and 1
        this.sections[i] = { image: "url:" + link, text: "" };
      }
      console.log(link);
      this.sections[i].image = "url:" + link;
      this.imageSources[i] = this.loadingImageSrc;

      this.getSrc("url:" + link).then((url) => {
        this.imageSources[i] = url;
      });
    },
    updateContent(i, content) {
      if (!this.sections[i]) {
        this.sections[i] = { image: undefined, text: "" };
      }
      this.sections[i].text = content;
    },

    submitForm() {
      this.$store.commit("setLoading", true);
      this.buttonDisabled = true;
      let data = new FormData();
      if (this.title !== "") {
        data.set("title", this.title);
      }
      if (this.description !== "") {
        data.set("description", this.description);
      }
      if (this.tags !== "") {
        data.set(
          "tags",
          this.tags.split(" ").filter((x) => x !== " " && x !== "")
        );
      }
      if (this.sections.length) {
        data.set("sections", this.sections.length);
      }
      if (this.mainImage !== this.postData.main_image) {
        data.set("main_image", this.mainImage);
      }
      this.sections.forEach((section, i) => {
        if (section.image) {
          data.set("image_" + i, section.image); //special case, if 'remove' is sent, image is deleted.
        }
        if (section.text !== "") {
          data.set("content_" + i, section.text);
        }
      });
      if (Array.from(data.keys()).length < 1) {
        this.$store.commit("setLoading", false);
        this.buttonDisabled = false;
        this.$notify({
          title: "Sorry",
          body: "You didn't make any changes",
          icon: "",
          type: "warning",
        });
        return;
      }
      axios
        .put("/api/v1/posts/edit/" + this.postData.id + "/", data)
        .then((res) => {
          console.log(res);
          this.$store.commit("setLoading", false);
          this.$emit("finished");
        })
        .catch((err) => {
          if (!err.response || err.response.status == 500) {
            this.$notify({
              title: "sorry",
              body: "there seems to be an issue with the servers, please try again after a few minutes.",
              type: "danger",
              duration: 5000,
            });
            this.$store.commit("setLoading", false);
            this.buttonDisabled = false;
            return;
          }
          Object.keys(err.response.data).forEach((key) => {
            switch (key) {
              case "title":
                this.$refs.titleBox.$refs.titleError.addErrors(
                  err.response.data[key]
                );
                break;
              case "slug":
                break;
              case "tags":
                this.$refs.titleBox.$refs.tagsError.addErrors(
                  err.response.data[key]
                );
                break;
              case "description":
                this.$refs.titleBox.$refs.descriptionError.addErrors(
                  err.response.data[key]
                );
                break;
              default:
                if (key.startsWith("image") || key.startsWith("content")) {
                  this.$refs[
                    "section" + key[key.length - 1]
                  ][0].$refs.error.addErrors(err.response.data[key]);
                } else {
                  this.$refs[
                    "section" + (this.sections.length - 1)
                  ][0].$refs.error.addError(
                    "There is an issue with your edit."
                  );
                  this.$refs[
                    "section" + (this.sections.length - 1)
                  ][0].$refs.error.addErrors(err.response.data[key]);
                }
            }
          });
          this.buttonDisabled = false;

          this.$store.commit("setLoading", false);
        });
    },
    getPreview(image) {
      if (this.matchYoutube(image)) {
        let code = this.matchYoutube(image)[1];
        return this.getYoutubeThumb(code);
      }
      return image;
    },
  },
  mounted() {
    this.postData.album.sections.forEach((section, i) => {
      if (section.image || section.external_url) {
        let url = section.image || section.external_url;
        this.imageSources[i] = this.loadingImageSrc;
        this.getSrc(url).then((src) => {
          this.imageSources[i] = src;
        });
      }
    });
  },
  computed: {
    imageSections() {
      return this.postData.album.sections;
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-section {
  width: inherit;
  background-color: $grey-500;
  height: fit-content;
  display: flex;
  border-radius: $border-radius;
  margin: $item-margin;
  padding: 0.5rem;
  justify-content: space-between;
  min-height: 10rem;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.2rem;
  padding: $item-margin;
  column-gap: 0.2rem;
}
.small-image-container {
  width: 4rem;
}
.text {
  font-size: 0.8rem;
  color: $white;
}
.small-image {
  border-radius: $border-radius;
  cursor: pointer;
  &:hover {
    outline: $info-500 solid 2px;
  }
}
.clicked {
  outline: $yellow solid 2px !important;
}
</style>
