<template>
  <div class="page-make-post" v-if="postData">
    <post-edit-form
      :postData="postData"
      @finished="goBackToPost()"></post-edit-form>
  </div>
</template>

<script>
import axios from "axios";
import PostEditForm from "../components/posts/PostEditForm.vue";
export default {
  name: "MakePost",
  components: {
    PostEditForm,
  },
  data() {
    return {
      postData: undefined,
    };
  },
  methods: {
    checkForPostData() {
      return new Promise((resolve) => {
        this.$store.commit("setLoading", true);
        if (this.$store.state.nextViewPost !== undefined) {
          this.postData = this.$store.state.nextViewPost;
          this.$store.commit("setLoading", false);
          axios.post("api/v1/posts/id/" + this.$route.params.id + "/", {
            incrementViews: true,
          });
          resolve();
        } else {
          axios
            .get("api/v1/posts/id/" + this.$route.params.id + "/")
            .then((res) => {
              console.log(res);
              this.postData = res.data;
              document.title = this.postData.title + " | icandoathing";

              this.$store.commit("setLoading", false);
              resolve();
              axios.post("api/v1/posts/id/" + this.$route.params.id + "/", {
                incrementViews: true,
              });
            })
            .catch((err) => {
              //TODO show custom message for not found
              console.log(err);
              resolve();
              this.$store.commit("setLoading", false);
              this.$router.push("/");
            });
        }
      });
    },
    goBackToPost() {
      this.$router.push(
        "/thing/" + this.postData.id + "/" + this.postData.slug
      );
    },
  },
  mounted() {
    this.checkForPostData().then(() => {
      document.title = "editing: " + this.postData.title + " | icandoathing";
    });
  },
};
</script>

<style lang="scss" scoped>
.page-make-post {
  width: 100%;
}
</style>
